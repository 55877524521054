<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'boardOfDirectors' | translate }}
      </h1>
  </div>

  <div class="article-content">

    <div class="employee-box col-12">
      <ul class="no-decor">
        <li>{{ 'boardMemebers1' | translate }}</li>
        <li>{{ 'boardMemebers2' | translate }}</li>
        <li>{{ 'boardMemebers3' | translate }}</li>
        <li>{{ 'boardMemebers4' | translate }}</li>
        <li>{{ 'boardMemebers5' | translate }}</li>
        
      </ul>        
    </div>

  </div>

  
</div>

